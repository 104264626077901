.logo {
  width: 6.5rem;
}

/* Add mobile responsiveness */
@media (max-width: 768px) {
  .logo {
    width: 2rem; /* Adjust the width for smaller screens */
  }
}

@media (max-width: 1024) {
  .logo {
    width: 4rem; /* Adjust the width for smaller screens */
  }
}
