.frostMaidenImg {
  height: 7.25rem;
  padding: 1vw;
  max-width: 250px;
}

@media (max-width: 550px) {
  .frostMaidenImg {
    display: none;
    width: 30vw;
    max-width: 150px;
  }
}
