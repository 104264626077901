.headerBackdrop {
  width: 100%;
  flex-wrap: wrap;
  height: 6.5rem;
  display: flex;
}

.headerRow {
  height: 6.5rem;
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  margin-left: 1rem;
}
