@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Runic&display=swap");

.adventureContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 8rem;
  width: 50rem;
  margin: 1rem;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;
  background-color: #dfdede;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.textContainer {
  margin-right: 5vw;
}

.adventureImgContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title {
  font-family: var(--nunito-font);
  font-size: 1.5rem;
  color: rgb(46, 45, 45);
}

.description {
  font-family: var(--nunito-font);
  font-size: 1rem;
  color: rgb(46, 45, 45);
}

@media (max-width: 850px) {
  .textContainer {
    margin-right: 2vw;
  }

  .adventureContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 20rem;
    width: 20rem;
    margin: 1rem;
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10px;
    background-color: #dfdede;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  .adventureImgContainer {
    display: none;
    height: 15rem;
    width: 10rem;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    position: relative;
  }

  .title {
    font-family: var(--nunito-font);
    font-size: 1.5rem;
    color: rgb(46, 45, 45);
  }

  .description {
    font-family: var(--nunito-font);
    font-size: 0.75rem;
    color: rgb(46, 45, 45);
  }
}
