@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&family=Nunito:wght@500;800&family=Raleway:wght@500&display=swap");

/* Define breakpoints for responsive design */
@media (max-width: 1024px) {
  .overlay_bards_cafe {
    font-size: 5rem !important; /* Font size for screens smaller than 950px */
  }
  /* Additional styles for the Header component */
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: top;
  }

  .overlay_bards_cafe {
    position: absolute;
    white-space: nowrap;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Dancing Script", cursive;
    font-size: 10rem;
  }
  .fullWidthImageContainer {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    position: relative;
  }
  .navigationLinks {
    position: absolute; /* Position the navigation links */
    bottom: 0.5rem; /* Adjust the distance from the bottom */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center horizontally */
    display: flex;
    justify-content: center;
    gap: 2rem; /* Adjust the gap between links */
  }

  .socialLogo {
    height: 1rem;
    width: auto;
  }

  .navLink {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .overlay_bards_cafe {
    font-size: 3rem !important; /* Font size for screens smaller than 768px */
  }
  /* Additional styles for the Header component */
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: top;
  }

  .overlay_bards_cafe {
    position: absolute;
    white-space: nowrap;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Dancing Script", cursive;
    font-size: 15rem;
  }
  .backgroundImage {
    width: auto; /* Reset width to auto */
    height: 100%; /* Take full height */
    object-fit: cover; /* Cover the container */
    object-position: center; /* Center the image */
  }
  .fullWidthImageContainer {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    position: relative;
  }
  .navigationLinks {
    position: absolute; /* Position the navigation links */
    bottom: 0.5rem; /* Adjust the distance from the bottom */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center horizontally */
    display: flex;
    justify-content: center;
    gap: 2rem; /* Adjust the gap between links */
  }

  .socialLogo {
    height: 1rem;
    width: auto;
  }

  .navLink {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
  }
}

@media (max-width: 450px) {
  .overlay_bards_cafe {
    font-size: 2.5rem !important; /* Font size for screens smaller than 768px */
  }
  .backgroundImage {
    width: auto; /* Reset width to auto */
    height: 45rem; /* Set height to 200% of its container */
    min-width: 100%; /* Ensure the image is at least as wide as its container */
    object-fit: cover; /* Cover the container */
    object-position: center; /* Center the image */
    background-size: auto 100%;
    margin-left: -5rem; /* Ensure the image's width is auto and height is 100% */
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: top;
  }
  .fullWidthImageContainer {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    position: relative;
  }
  .navigationLinks {
    position: absolute; /* Position the navigation links */
    bottom: 0.5rem; /* Adjust the distance from the bottom */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center horizontally */
    display: flex;
    justify-content: center;
    gap: 2rem; /* Adjust the gap between links */
  }

  .socialLogo {
    height: 1rem;
    width: auto;
  }
  .navLink {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
  }
}

@media (max-width: 300px) {
  .overlay_bards_cafe {
    font-size: 1.5rem !important; /* Font size for screens smaller than 768px */
  }
  .backgroundImage {
    width: auto; /* Reset width to auto */
    height: 45rem; /* Set height to 200% of its container */
    min-width: 100%; /* Ensure the image is at least as wide as its container */
    object-fit: cover; /* Cover the container */
    object-position: center; /* Center the image */
    background-size: auto 100%;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: top;
  }
  .fullWidthImageContainer {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    position: relative;
  }
  .navigationLinks {
    position: absolute; /* Position the navigation links */
    bottom: 0.5rem; /* Adjust the distance from the bottom */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center horizontally */
    display: flex;
    justify-content: center;
    gap: 2rem; /* Adjust the gap between links */
  }

  .socialLogo {
    height: 1rem;
    width: auto;
  }
  .navLink {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
  }
}

@media (min-width: 1024px) {
  .backgroundImage {
    width: 100%;
    height: auto; /* Adjust height automatically */
    flex: 1; /* Take remaining space */
    object-fit: cover; /* Ensure the image covers the container */
    margin-top: -100px;
  }
  .fullWidthImageContainer {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    position: relative;
  }
  /* Additional styles for the Header component */
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: top;
  }
  h1 {
    font-weight: 200;
  }
  .overlay_bards_cafe {
    position: absolute;
    white-space: nowrap;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Dancing Script", cursive;
    font-size: 7rem;
    color: black;
  }
  .navigationLinks {
    position: absolute; /* Position the navigation links */
    bottom: 0.5rem; /* Adjust the distance from the bottom */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center horizontally */
    display: flex;
    justify-content: center;
    gap: 2rem; /* Adjust the gap between links */
  }

  .socialLogo {
    height: 2rem;
    width: auto;
  }

  .navLink {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
  }
}

.Header {
  z-index: 1; /* Ensure the Header component is above the background image */
}

.gradientOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 45%; /* Adjust the height of the gradient overlay as needed */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
