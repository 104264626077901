.header {
  width: 100vw;
  height: 3rem;
  display: inline-block;
}

.flexboxContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.tabs {
  display: flex;
  justify-content: flex-start;
  height: 3rem;
  font-family: var(--dancing-font);
  /* font-size: 5.5rem; */
  color: rgb(46, 45, 45);
}

.activeTab {
  text-decoration: none;
  cursor: pointer;
  background: var(--active-tab-color);

  /* text settings */
  box-shadow: inset 0px 1px 1px var(--stroke-color);
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  font-family: Georgia, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 1.5rem;
  color: rgb(46, 45, 45);
  text-align: center;
}

.tab {
  text-decoration: none;
  cursor: pointer;

  /* text settings */

  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  font-family: Georgia, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 1.2rem;
  text-align: center;
  color: var(--black-text-color);
  box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.title {
  font-family: var(--dancing-font);
  font-style: normal;
  font-weight: 400;
  color: var(--light-text-color);
  margin: 0;
  margin-left: 2vw;

  text-shadow: -1px -1px 0 var(--stroke-color), 1px -1px 0 var(--stroke-color),
    -1px 1px 0 var(--stroke-color), 1px 1px 0 var(--stroke-color);
}

.linksContainer {
  display: flex;
  align-items: center;
}

.linkedin {
  height: 1.2rem;
}

@media (max-width: 550px) {
  .header {
    width: auto;
  }

  .tabs {
    font-size: small;
  }
}
