@import url("https://fonts.googleapis.com/css2?family=Ballet:opsz@16..72&family=Caveat&family=Dancing+Script:wght@500&family=Nunito:wght@500;800&family=Raleway:wght@500&display=swap");

.App {
  text-align: center;
  background-color: #ffffff;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.big_stories {
  font-family: Georgia, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 3rem;
  margin-top: 3rem;
}

.signature {
  margin-top: 3rem;
  font-size: 6rem;
  font-family: "Dancing Script", cursive;
}

@media (max-width: 450px) {
  .big_stories {
    font-family: Georgia, serif;
    font-weight: 400;
    font-style: italic;
    font-size: 1.8rem;
    margin-top: 3rem;
  }
  .signature {
    font-size: 3rem;
    font-family: "Dancing Script", cursive;
  }

  .letter {
    background-color: #ffffff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: left; /* Align items to the left */
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: black;
    max-width: 75rem; /* Set maximum width */
    margin: 0 auto; /* Center horizontally */
    text-align: left; /* Align text to the left */
    font-family: Georgia, serif;
    font-weight: 400;
    font-style: italic;
  }
}

.letter {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left; /* Align items to the left */
  justify-content: flex-start;
  font-size: calc(20px + 2vmin);
  color: black;
  max-width: 75rem; /* Set maximum width */
  margin: 0 auto; /* Center horizontally */
  text-align: left; /* Align text to the left */
  font-family: Georgia, serif;
  font-weight: 400;
  font-style: italic;
}

.App-link {
  color: #61dafb;
}

.social_media {
  display: flex;
  flex-direction: row;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
